import { Arrays } from "ext/array";
import "ext/js";
var DEBUG = false;
var SUBSTITUTIONS = [
    [" ⟵>", " ⇌"],
    [" ->", " ⟶"],
    [" <-", " ⟵"],
    [" degC", " °C"],
    [" degF", " °F"],
];
/**
 * ChemMark is a codename for a technology for writing text
 * containing chemical formulas and other scientific
 */
export var ChemMark;
(function (ChemMark) {
    /** Description to use in the UI on toggles */
    ChemMark.uiDescription = function () { return "chemtext.description"; }; // Context will translate this
    /** Limit on the number of characters where ChemMark will be applied. */
    ChemMark.FREE_TIER_CHAR_LIMIT = 7;
    /** Icon used to represent ChemMark, without the "fa-" prefix */
    ChemMark.faIconName = "k,brain-ai-4";
    /** Parse the text without ChemMark features. */
    function parseBasic(input) {
        var formattedText = { segments: [] };
        // Break it down by newlines.
        var lines = input.split("\n");
        // Insert newline between every line.
        for (var _i = 0, lines_1 = lines; _i < lines_1.length; _i++) {
            var line = lines_1[_i];
            formattedText.segments.push({ type: "normal", text: line });
            formattedText.segments.push({ type: "newline" });
        }
        // Drop the last newline.
        formattedText.segments.pop();
        return formattedText;
    }
    ChemMark.parseBasic = parseBasic;
    function parseSmartLimited(input, config) {
        var text = parseSmart(input, config);
        if (input.length > ChemMark.FREE_TIER_CHAR_LIMIT) {
            // Add warning emojis at start and end.
            text.segments.unshift({
                type: "normal",
                text: "⚠️"
            });
            text.segments.push({
                type: "normal",
                text: "⚠️"
            });
        }
        return text;
    }
    ChemMark.parseSmartLimited = parseSmartLimited;
    function parseSmart(input, config) {
        var parseState = "normal";
        var segments = [];
        var currentSegment = "";
        function maybeCloseCurrentSegment() {
            if (currentSegment != "") {
                switch (parseState) {
                    case "normal":
                        segments.push({ type: "normal", text: currentSegment });
                        break;
                    case "subscript":
                    case "number":
                        segments.push({ type: "subscript", text: currentSegment });
                        break;
                    case "orbital":
                    case "exponent":
                        segments.push({ type: "superscript", text: currentSegment });
                        break;
                }
            }
        }
        for (var _i = 0, input_1 = input; _i < input_1.length; _i++) {
            var char = input_1[_i];
            if (DEBUG)
                console.log("Character: ", char);
            if (char == "\n") {
                // Close current segment (if any), insert a newline and reset for new parsing.
                maybeCloseCurrentSegment();
                segments.push({ type: "newline" });
                currentSegment = "";
                parseState = "normal";
                continue;
            }
            switch (parseState) {
                case "normal":
                    if ((char == '2' || char == '3') && endsInUnit(currentSegment)) {
                        // This is a unit.
                        segments.push({ type: "normal", text: currentSegment });
                        segments.push({ type: "superscript", text: char });
                        parseState = "normal";
                        currentSegment = '';
                    }
                    else if (isNumeric(char) && isOrbitalSegment(currentSegment)) {
                        // This is an orbital spec.
                        segments.push({ type: "normal", text: currentSegment });
                        parseState = "orbital";
                        currentSegment = char;
                    }
                    else if (isNumeric(char) && isInsideFormula(currentSegment)) {
                        // Switch to subscript.
                        segments.push({ type: "normal", text: currentSegment });
                        parseState = "number";
                        currentSegment = char;
                    }
                    else if (isPlusMinus(char) && isInsideFormula(currentSegment)) {
                        // Single charge notation.
                        segments.push({ type: "normal", text: currentSegment });
                        segments.push({ type: "superscript", text: char });
                        currentSegment = "";
                    }
                    else if (isPlusMinus(char) && currentSegment == "" && isLastSegmentSuperscriptCharge(segments, char)) {
                        // ++ -> 2+
                        Arrays.last(segments).text = "2" + char;
                    }
                    else if (currentSegment.length >= 2
                        && (char === "C" || char === "F")
                        && (currentSegment.last(1) === "o")
                        && isNumeric(currentSegment[currentSegment.length - 2])) {
                        // Temperatures. (oF and oC)
                        currentSegment = currentSegment.replaceLast(1, "°") + char;
                    }
                    else if (char == ")" && config.stateOfMatterStyle == "subscript" && allowStateSymbol(currentSegment, segments)) {
                        // State symbol
                        var ssLen = getStateSymbolLength(currentSegment);
                        // Split the string. Insert the original part as normal, then the state symbol as subscript.
                        var stateSymbol = currentSegment.last(ssLen) + ")";
                        // There are various cases:
                        // 1. The first part is non-empty.
                        if (currentSegment.length > ssLen) {
                            segments.push({ type: "normal", text: currentSegment.substring(0, currentSegment.length - ssLen) });
                            segments.push({ type: "subscript", text: stateSymbol });
                        }
                        else if (segments.length == 0) {
                            //2. The first part is empty. If there are no previous segments, use "normal".
                            segments.push({ type: "normal", text: stateSymbol });
                        }
                        else {
                            var lastSegment = Arrays.last(segments);
                            switch (lastSegment.type) {
                                case "subscript":
                                    lastSegment.text += stateSymbol;
                                    break;
                                case "superscript":
                                    segments[segments.length - 1] = {
                                        type: "subsuperscript",
                                        super: lastSegment.text,
                                        sub: stateSymbol,
                                    };
                                    break;
                                case "subsuperscript":
                                    lastSegment.sub += stateSymbol;
                                    break;
                                case "newline":
                                case "normal":
                                    segments.push({ type: "normal", text: stateSymbol });
                                    break;
                            }
                        }
                        // Reset for next segment.
                        currentSegment = "";
                    }
                    else if (char === "_" && doesSegmentEndIn(currentSegment, ["10"])) {
                        // Allow superscript and subscripts only if the "base" is 10.
                        segments.push({ type: "normal", text: currentSegment });
                        parseState = "subscript";
                        currentSegment = "";
                    }
                    else if (char === "^" && doesSegmentEndIn(currentSegment, ["10", "mol L", "molL"])) {
                        segments.push({ type: "normal", text: currentSegment });
                        parseState = "exponent";
                        currentSegment = "";
                    }
                    else {
                        currentSegment += char;
                        currentSegment = checkSubstitutions(currentSegment);
                    }
                    break;
                case "number":
                    if (isNumeric(char)) {
                        currentSegment += char;
                    }
                    else if (isPlusMinus(char)) {
                        // This is an ion.
                        // We only support single-digit charges, hence if the current
                        // segment is longer than 1, it also contains an atom count.
                        if (currentSegment.length > 1) {
                            // Charge and atom number.
                            segments.push({
                                type: "subsuperscript",
                                sub: currentSegment.first(currentSegment.length - 1),
                                super: currentSegment.last(1) + char,
                            });
                            parseState = "normal";
                            currentSegment = '';
                        }
                        else if (isChargeNumberHeuristic(currentSegment, Arrays.last(segments))) {
                            // This is tricky! It could be a charge, or an atom
                            // number and numberless charge. Apply a heuristic.
                            // Just charge.
                            segments.push({ type: "superscript", text: currentSegment + char });
                            parseState = "normal";
                            currentSegment = '';
                        }
                        else {
                            // Charge and atom number.
                            segments.push({
                                type: "subsuperscript",
                                sub: currentSegment,
                                super: char,
                            });
                            parseState = "normal";
                            currentSegment = '';
                        }
                    }
                    else if (isOrbitalCharacter(char) && currentSegment.length >= 1) {
                        // This can arise in abbreviated configurations such as "[Kr]4d10"
                        currentSegment += char;
                        maybeMergeWithLastSegment(segments, { type: "normal", text: currentSegment });
                        parseState = "orbital";
                        currentSegment = "";
                    }
                    else {
                        // There are various cases, in some we will close the segment and switch to
                        // normal. This is controlled by this variable.
                        var shouldClose = true;
                        var nextSegment = "";
                        if (char == ".") {
                            // Hydration interpunct OR LMNO.
                            // LMNO: https://en.wikipedia.org/wiki/Lithium_ion_manganese_oxide_battery
                            // What we do here is continue the number if the previous segment is
                            // "Li" or "Mn" then we allow "fractional" values and continue the
                            // number instead of closing.
                            if (allowLMNO(segments)) {
                                currentSegment += char;
                                shouldClose = false;
                            }
                            else {
                                // Hydration interpunct. We are relying here on the fact that
                                // most hydrates have the first bit ending in a number, e.g. CuSO4, Ba(OH)2
                                nextSegment = " · ";
                            }
                        }
                        else {
                            // Normal character.
                            nextSegment = char;
                        }
                        if (shouldClose) {
                            // Close number and start normal.
                            segments.push({ type: "subscript", text: currentSegment });
                            parseState = "normal";
                            currentSegment = nextSegment;
                        }
                    }
                    break;
                case "orbital":
                    if (isNumeric(char)) {
                        currentSegment += char;
                    }
                    else if (isOrbitalCharacter(char) && currentSegment.length >= 2) {
                        // Electron configuration written munged together, i.e.
                        // "1s22s22p6..." instead of "1s2 2s2 2p6 ..."
                        // Remove the last character from the segment.
                        segments.push({ type: "superscript", text: currentSegment.substring(0, currentSegment.length - 1) });
                        segments.push({ type: "normal", text: currentSegment.last(1) + char });
                        currentSegment = "";
                        // Stay in orbital parse state.
                    }
                    else {
                        segments.push({ type: "superscript", text: currentSegment });
                        parseState = "normal";
                        currentSegment = char;
                    }
                    break;
                case "exponent":
                case "subscript":
                    if (isNumeric(char) || char === "-") {
                        currentSegment += char;
                    }
                    else {
                        var lastSegment = Arrays.last(segments);
                        if ((currentSegment.length === 0 || currentSegment === "-") && lastSegment.type === "normal") {
                            // Current segment is invalid, reverse it out.
                            currentSegment = lastSegment.text + (parseState === "exponent" ? "^" : "_") + char;
                            segments.pop();
                        }
                        else {
                            // Happy case: complete the current segment
                            segments.push({ type: parseState === "exponent" ? "superscript" : "subscript", text: currentSegment });
                            currentSegment = char;
                        }
                        parseState = "normal";
                    }
            }
            if (DEBUG)
                console.log("currentSegment=" + currentSegment + ", state=" + parseState);
        }
        // Add last segment.
        maybeCloseCurrentSegment();
        return {
            segments: segments
        };
    }
    ChemMark.parseSmart = parseSmart;
})(ChemMark || (ChemMark = {}));
function checkSubstitutions(input) {
    for (var _i = 0, SUBSTITUTIONS_1 = SUBSTITUTIONS; _i < SUBSTITUTIONS_1.length; _i++) {
        var _a = SUBSTITUTIONS_1[_i], sequence = _a[0], substitution = _a[1];
        if (input.last(sequence.length) === sequence) {
            return input.replaceLast(sequence.length, substitution);
        }
    }
    return input;
}
function isNumeric(char) {
    return (char >= '0' && char <= '9');
}
function allowLMNO(previousSegments) {
    if (previousSegments.length >= 1) {
        var lastSegment = Arrays.last(previousSegments);
        if (lastSegment.type === "normal") {
            if (Arrays.includes(["Li", "Mn", "Ni"], lastSegment.text.last(2))
                || lastSegment.text == "O") {
                // Allow singular "O" but not something like "MnO"
                return true;
            }
        }
    }
    return false;
}
function doesSegmentEndIn(segment, candidates) {
    for (var _i = 0, candidates_1 = candidates; _i < candidates_1.length; _i++) {
        var candidate = candidates_1[_i];
        if (segment.last(candidate.length) === candidate) {
            return true;
        }
    }
    return false;
}
/** Return the length of the state symbol, or 0 if no state symbol. */
function getStateSymbolLength(currentSegment) {
    // Lookbehind is not supported:
    // https://stackoverflow.com/questions/51568821/works-in-chrome-but-breaks-in-safari-invalid-regular-expression-invalid-group
    // Capture as two groups and discard the first one.
    var match = currentSegment.match(/(^|[^ ])(\((aq|g|s|l))$/);
    if (match)
        return match[2].length;
    return 0;
}
/** (aq), (g), (s) and (l) */
function allowStateSymbol(currentSegment, previousSegments) {
    var s1 = getStateSymbolLength(currentSegment);
    if (s1 == 0)
        return false;
    // Special case, e.g. "f(g)"
    var start = currentSegment.substr(0, currentSegment.length - s1);
    if (start == "f")
        return false;
    // If it's all lowercase, chances are it's not a formula so also ignore.
    if (start.length > 0 && start.toLowerCase() == start)
        return false;
    if (previousSegments.length == 0)
        return true;
    var lastSegment = Arrays.last(previousSegments);
    if (lastSegment.type != "normal")
        return true;
    return true;
}
function isPlusMinus(char) {
    return (char == '+' || char == '-');
}
function isInsideFormula(segment) {
    var lastChar = segment.last(1);
    return segment.length > 0
        && (isTextCharacter(lastChar)
            || lastChar == ')'
            || lastChar == ']');
}
function isTextCharacter(char) {
    return (char >= 'A' && char <= 'Z') || (char >= 'a' && char <= 'z');
}
function isOrbitalCharacter(char) {
    return ["s", "p", "d", "f"].indexOf(char) != -1;
}
function isOrbitalSegment(segment) {
    return segment.length >= 2 && isOrbitalCharacter(segment.last(1)) && isNumeric(segment[segment.length - 2]);
}
/** Exceptions, where the following number is NOT a charge number. */
var NOT_CHARGE_NUMBER_COMPOUNDS = ["PO", "NH", "NO", "VO", "IO"];
/**
 * Heuristic function that determines whether the current
 * number is a charge or an atom number.
 * @return {true} if the number is a charge.
 */
function isChargeNumberHeuristic(number, prevSegment) {
    // If the previous segment is quite short, it's likely it's a charge.
    // However, there are exception, such "PO", where it's not a charge number.
    if (prevSegment.type == "subsuperscript" || prevSegment.type == "newline")
        return true;
    // If the number is a 1, it's very very likely it's a charge.
    if (number === "1")
        return true;
    var text = extractLastTextCharacters(prevSegment.text);
    if (text.length <= 2 && NOT_CHARGE_NUMBER_COMPOUNDS.indexOf(text) === -1) {
        return true;
    }
    return false;
}
function isLastSegmentSuperscriptCharge(segments, chargeChar) {
    if (segments.length == 0)
        return false;
    var lastSegment = Arrays.last(segments);
    return lastSegment.type == "superscript"
        && lastSegment.text == chargeChar;
}
/** Merges a normal segment with the last segment, if it exists and is also normal. Otherwise appends. */
function maybeMergeWithLastSegment(segments, segment) {
    if (segments.length == 0) {
        segments.push(segment);
        return;
    }
    var lastSegment = Arrays.last(segments);
    if (lastSegment.type == "normal") {
        lastSegment.text += segment.text;
    }
}
/**
 * Extract last part of a string made of solely text characters.
 * E.g. "6 Fe" -> "Fe"
 */
function extractLastTextCharacters(input, limit) {
    var index;
    var lowerBound = limit ? Math.max(input.length - 1 - limit, 0) : 0;
    for (index = input.length - 1; index >= lowerBound; index--) {
        if (!isTextCharacter(input[index])) {
            break;
        }
    }
    return input.substr(index + 1);
}
var UNITS = ["cm", "m", "mm", "dm"];
function endsInUnit(input) {
    var lastCharacters = extractLastTextCharacters(input, 3);
    return UNITS.indexOf(lastCharacters) !== -1;
}
