var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { apparatusClass } from "apparatus/library";
import { apparatusCategories } from "apparatus/specs";
import * as React from "react";
import { Trans, useTranslation } from "react-i18next";
import { compileSearchTerms, NO_SEARCH_TERM_SENTINEL, searchApparatus } from "../../apparatus/search";
import apparatusSpecList from "../../apparatus/spec_list";
import { ApparatusItem } from "./apparatus_item";
/**
 * Component that displays a list of apparatus. Clicking on an item adds it.
 * This is also used for a filtered apparatus search.
 */
export var ApparatusList = function (_a) {
    var searchTerm = _a.searchTerm, module = _a.module, onSwitchModule = _a.onSwitchModule;
    var t = useTranslation().t;
    var compiledApparatusSpecs = React.useMemo(function () {
        return apparatusSpecList
            // Filter out apparatus that is not yet released.
            .filter(function (spec) { var _a; return ((_a = spec.released) !== null && _a !== void 0 ? _a : true); })
            .map(function (spec) {
            var properties = apparatusClass(spec.type).properties;
            // Translate apparatus name.
            var name = t("apparatus." + spec.type + ".name", properties.label);
            // Translate search terms. Fallback to a non-existent key that then gets removed
            var searchTermsString = t("apparatus." + spec.type + ".search", NO_SEARCH_TERM_SENTINEL).toLowerCase();
            return __assign(__assign({}, spec), { name: name, lowerCaseName: name.toLowerCase(), search: compileSearchTerms(searchTermsString, name) });
        });
    }, [t]);
    var apparatusByGroups = React.useMemo(function () {
        return {
            "chemistry": splitApparatusByCategory(compiledApparatusSpecs, "chemistry"),
            "heating": splitApparatusByCategory(compiledApparatusSpecs, "heating"),
            "various": splitApparatusByCategory(compiledApparatusSpecs, "various"),
            "shapes": splitApparatusByCategory(compiledApparatusSpecs, "shapes"),
            "biology": splitApparatusByCategory(compiledApparatusSpecs, "biology"),
            "physics": splitApparatusByCategory(compiledApparatusSpecs, "physics"),
            "distillation": splitApparatusByCategory(compiledApparatusSpecs, "distillation"),
            "measurement": splitApparatusByCategory(compiledApparatusSpecs, "measurement"),
            "transport": splitApparatusByCategory(compiledApparatusSpecs, "transport")
        };
    }, [compiledApparatusSpecs]);
    var isInSearchMode = searchTerm.length > 0;
    return React.createElement("div", { className: "sidebar-list" },
        React.createElement("div", { className: "scrollable-container", "data-simplebar": true },
            React.createElement("div", null, isInSearchMode
                ? React.createElement(SearchResults, { key: "searchResults", searchTerm: searchTerm, results: searchApparatus(searchTerm, compiledApparatusSpecs), onSwitchModule: onSwitchModule })
                : React.createElement(DefaultList, { key: "searchResults", groups: apparatusByGroups[module] }))));
};
var SearchResults = function (props) {
    var t = useTranslation().t;
    var moleculePartString = t("search.molecule_part_string");
    return React.createElement(React.Fragment, null,
        React.createElement("div", { key: "searchResultSummary", className: "search-results-summary" }, t("ui.result", { count: props.results.length })),
        props.results.map(function (a) {
            return React.createElement(ApparatusItem, __assign({ showCategory: true, isSearchMode: true, key: a.type }, a));
        }),
        props.results.length == 0
            ? (props.searchTerm.toLowerCase().indexOf(moleculePartString) > -1
                ? React.createElement(MolViewTip, null)
                : React.createElement(NoResultsTip, { onSwitchToShapes: function () { return props.onSwitchModule("shapes"); } }))
            : undefined);
};
/** Remembers the collapse state of groups. True = expanded. */
var groupCollapseMemo = {};
var DefaultList = function (props) {
    var t = useTranslation().t;
    function onHeaderClick(id, el) {
        var _a, _b;
        var collapsed = el.classList.contains("collapsed");
        groupCollapseMemo[id] = !collapsed;
        // If collapsing and the header is sticky,
        // scroll to the header element to prevent weird artifacts.
        if (collapsed) {
            var scrollable = document.getElementsByClassName("scrollable-container")[0];
            var sb = SimpleBar.instances.get(scrollable);
            var groupOffsetStart = (_b = (_a = el.parentElement) === null || _a === void 0 ? void 0 : _a.offsetTop) !== null && _b !== void 0 ? _b : 0;
            if (sb.getScrollElement().scrollTop > groupOffsetStart) {
                $(sb.getScrollElement()).animate({ scrollTop: groupOffsetStart }, 100);
            }
        }
    }
    return React.createElement(React.Fragment, null,
        props.groups.map(function (group) {
            var _a;
            var id = "apparatus-group-" + group.category.replace(/[^\w]/g, "_");
            var show = (_a = groupCollapseMemo[id]) !== null && _a !== void 0 ? _a : true;
            return [
                React.createElement("div", { className: "category-group-wrapper", key: group.category },
                    React.createElement("h3", { "data-toggle": "collapse", "aria-expanded": show ? "true" : "false", "data-target": "#" + id, onClick: function (e) { return onHeaderClick(id, e.nativeEvent.target); } },
                        React.createElement("i", { className: "fas fa-caret-right fa-fw" }),
                        t("category." + group.category)),
                    React.createElement("div", { className: "category-group collapse " + "show".if(show), key: "group_" + group.category, id: id }, group.apparatus.map(function (a) {
                        return React.createElement(ApparatusItem, __assign({ isSearchMode: false, key: a.type }, a));
                    })))
            ];
        }),
        props.extraView);
};
function splitApparatusByCategory(allApparatus, module) {
    return apparatusCategories[module].map(function (category) { return ({
        category: category,
        apparatus: allApparatus.filter(function (spec) { return spec.category == category; })
    }); });
}
var NoResultsTip = function (props) {
    var t = useTranslation().t;
    return React.createElement("div", { className: "text-secondary p-3" },
        React.createElement("i", { className: "fad fa-lightbulb-on text-warning fa-3x d-block my-3 text-center" }),
        React.createElement(Trans, { i18nKey: "ui.no_results_tip", values: { module: t("apparatus_module.shapes") } },
            "Didn't find an item you need? Try using ",
            React.createElement("span", { className: "href font-weight-bold", onClick: function () { return props.onSwitchToShapes(); } }, "Shapes"),
            " to draw it yourself or ",
            React.createElement("a", { href: "https://chemix.kampsite.co", className: "font-weight-bold", target: "_blank" }, "send us a suggestion.")));
};
export var PhysicsNotice = function () { return (React.createElement("div", { className: "p-3 text-white" },
    React.createElement("i", { className: "fad fa-poll-people fa-3x d-block my-3 text-center" }),
    React.createElement("p", null,
        "Physics items are coming this ",
        React.createElement("b", null, "May"),
        ". Vote for items you'd like to see first."),
    React.createElement("a", { href: "https://docs.google.com/forms/d/e/1FAIpQLSd8qDn_Ursbhz_eHewpQxgKSBsi3Pk9GTcf3gAQWy5knw-bUA/viewform", target: "_blank", className: "ui-button" }, "Vote"))); };
export var PhysicsNoticeMoreComingSoon = function () { return (React.createElement("div", { className: "m-3 pt-2 text-white border-top border-secondary" },
    React.createElement("i", { className: "fal fa-space-station-moon-alt fa-3x d-block my-3 text-center text-muted" }),
    React.createElement("p", null, "Physics module is in active development. More items coming soon."))); };
export var MolViewTip = function () { return (React.createElement("div", { className: "text-secondary p-3" },
    React.createElement("img", { src: "/images/molview.png", alt: "MolView", height: "48", width: "48", className: "d-block my-3 mx-auto" }),
    React.createElement(Trans, { i18nKey: "ui.molview_search_tip" },
        "Looking to draw molecules? Try ",
        React.createElement("a", { href: "https://molview.com/", className: "font-weight-bold", target: "_blank" }, "MolView")))); };
