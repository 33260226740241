import { mirrorX, strokeWidth } from "apparatus/draw";
import { Gradients } from "apparatus/gradients";
import { P, Pivot, R, Segments } from "apparatus/library/common";
import { Group, Path, Shape } from "paper";
var GRAY_CYLINDER = Gradients.cylinder("#848489".color());
var DeskLamp = /** @class */ (function () {
    function DeskLamp() {
        this.drivers = [
            {
                type: "rectangle",
                minX: -50, maxX: 300,
                minY: -340, maxY: -100,
                fromAppearance: function (a) { return P(a.headX, -a.headY).multiply(a.scale); },
                toAppearance: function (f, p, a) { return f({ headX: p.x / a.scale, headY: -p.y / a.scale }); },
                scale: 1.0,
            }
        ];
    }
    DeskLamp.prototype.render = function (appearance) {
        var armLength = 170;
        var armWidth = 20;
        // Solve 2-arm IK, from 0 to (headX, -headY)
        //         +
        //         |       XXX(headX, -headY)
        //         |     XXXXX
        //         |  XXXX  X
        //         |XX
        //      XXX|
        //  XXXX   |
        //  XX     |
        //    X    |
        //    X    |
        //     X   |
        //      X  |
        //      XX |
        //       XX+
        var headVector = P(appearance.headX, -appearance.headY);
        var length = Math.min(headVector.length, armLength * 1.99);
        headVector.length = length;
        var halfX = length / 2;
        var armAngle = Math.acos(halfX / armLength).toDegrees();
        var arm1 = Shape.Rectangle(R(-armWidth / 2, 0, armLength + armWidth, armWidth, Pivot.CENTER_LEFT), armWidth / 2);
        arm1.rotate(-armAngle, P(0, 0));
        arm1.fillColor = "#aaaaaa";
        arm1.withStroke(strokeWidth("default"), "#888888");
        var arm2 = arm1.clone();
        arm2.scale(-1.0, 1.0, P(halfX, 0));
        var arms = new Group([arm1, arm2]);
        arms.rotate(headVector.angle, P(0, 0));
        var lampAttachment = Shape.Rectangle(R(0, -8, 16, 40, Pivot.TOP_CENTER), 5.0);
        lampAttachment.withStroke(strokeWidth("default"), "#61605f").withFill("#888888");
        var lampHead = new Path(Segments([[0, 20], , [50, 0]], [[50, 100]]));
        mirrorX(lampHead);
        lampHead.closePath();
        lampHead.withStroke(strokeWidth("default"), "#61605f").withGradientFill("right", GRAY_CYLINDER);
        var lightColor = this.glowColor(appearance.lightColor);
        var lightCone = new Path(Segments([[50, 100]], [[80, 200]]));
        mirrorX(lightCone);
        lightCone.closePath();
        lightCone.withGradientFill("down", [[lightColor + "80", 0.0], [lightColor + "00", 1.0]]);
        var lampTop = new Group([
            lampAttachment, lightCone, lampHead
        ]);
        lampTop.pivot = P(0, 0);
        lampTop.position = headVector;
        lampTop.rotation = -appearance.angle;
        var base = Shape.Rectangle(R(0, 0, 120, 25, Pivot.TOP_CENTER), 2.0);
        base.withStroke(strokeWidth("default"), "#61605f").withGradientFill("right", GRAY_CYLINDER);
        var graphic = new Group([arms, base, lampTop]);
        // Apply scale, around the pivot.
        graphic.scale(appearance.scale, P(0, 0));
        return {
            graphic: graphic,
            hitShape: graphic.bounds.toShape(),
            pivot: P(0, 0),
            snapping: base.placeable(),
        };
    };
    DeskLamp.prototype.glowColor = function (color) {
        switch (color) {
            case "yellow": return "#fce653";
            case "red": return "#ff957a";
            case "blue": return "#5192f5";
            case "green": return "#67e22d";
            case "uv": return "#9200e2";
        }
    };
    DeskLamp.properties = {
        label: "Desk lamp",
        flippable: true,
        defaultAppearance: {
            headX: 10,
            headY: 300,
            angle: 30,
            scale: 1.0,
            lightColor: "yellow"
        },
        appearanceSpecs: [
            {
                spec: "slider", key: "angle", label: "Head angle",
                min: 0, max: 180, pipsStep: 90,
            },
            {
                spec: "slider", key: "scale", label: "Scale",
                min: 0.6, max: 1.2, step: 0.01, unit: "%",
            },
            {
                spec: "color-wells", key: "lightColor", label: "Colour",
                colors: [
                    { value: "yellow", label: "Yellow" },
                    { value: "red", label: "Red" },
                    { value: "green", label: "Green" },
                    { value: "blue", label: "Blue" },
                    { value: "uv", label: "UV", hex: "#9200e2" },
                ]
            },
        ],
    };
    return DeskLamp;
}());
export { DeskLamp };
